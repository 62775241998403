import domReady from '@roots/sage/client/dom-ready';
import Alpine from 'alpinejs';
import Splide from '@splidejs/splide';
import sticky from 'alpinejs-sticky';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab }  from '@fortawesome/free-brands-svg-icons';

library.add(fas, fab);
dom.watch();
import 'flowbite';
/**
 * Application entrypoint
 */
domReady(async () => {
  // Enable Alpine
  window.Alpine = Alpine;
  Alpine.plugin(sticky);
  Alpine.start();

  // Quote Form
  const quote_style = document.getElementById('input_9_1');
  const quote_img = document.getElementById('quote_img');
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const product_type = urlParams.get('ProductType');
  let style = urlParams.get('Style');
  if (!style) {
    style = 0;
  }
  let source = '/wp-content/uploads/2024/04/GAP_Quote_';
  const url_file_name = source.concat(style, ".jpg");
  if (quote_img) {
    quote_img.setAttribute('src', url_file_name);
  }
  if (quote_style) {
    if (style) {
      quote_style.value = style;
    }
    
    quote_style.onchange = (e) => {
      console.log('Style:', quote_style.value);
      if (quote_style.value === '23') {
        source = '/wp-content/uploads/2024/06/GAP_Quote_';
      } else {
        source = '/wp-content/uploads/2024/04/GAP_Quote_';
      }
      const file_name = source.concat(quote_style.value, ".jpg");
      if (quote_img) {
        quote_img.setAttribute('src', file_name);
      }
    };
  }

  const banner_id = document.getElementById('banner');
  if(banner_id){
    const banner = new Splide( '#banner', {
      type: 'loop',
      perPage: 1,
      speed: 1000,
      interval: 8000,
      autoplay: true,
      drag: true,
      pauseOnHover: false
    } ).mount();
  }

  const main_id = document.getElementById('main_carousel');
  const thumbnails_id = document.getElementById('thumbnail_carousel');
  if(main_id && thumbnails_id){
    const main = new Splide( '#main_carousel', {
      type      : 'fade',
      rewind    : true,
      pagination: false,
      arrows    : false,
    } );
    const thumbnails = new Splide( '#thumbnail_carousel', {
      fixedWidth  : 90,
      fixedHeight : 90,
      gap         : 10,
      rewind      : false,
      pagination  : false,
      isNavigation: true,
    } );
    main.sync( thumbnails );
    main.mount();
    thumbnails.mount();
  }

  const slides = document.querySelectorAll('.slide');
  if (slides.length > 0) {
    let currentIndex = 0;
    const slideCount = slides.length;
    slides[currentIndex].style.left = '0';

    function cycleSlides() {
      let nextIndex = (currentIndex + 1) % slideCount;

      slides[currentIndex].style.left = '-100%';
      slides[nextIndex].style.left = '0';

      currentIndex = nextIndex;
    }
    setInterval(cycleSlides, 8000);
  }

  (function() {
    const buttons = document.querySelectorAll('.read-more-button');
    if (buttons.length > 0) {
      buttons.forEach((button, index) => {
        button.addEventListener('click', function() {
          const moreContent = document.querySelectorAll('#moreContent')[index];
          if (moreContent.classList.contains('read-more-hidden')) {
            moreContent.classList.remove('read-more-hidden');
            moreContent.classList.add('read-more-visible');
            this.textContent = 'Read Less';
          } else {
            moreContent.classList.remove('read-more-visible');
            moreContent.classList.add('read-more-hidden');
            this.textContent = 'Read More';
          }
        });
      });
    }
  })();

  document.getElementById('video-home-page').addEventListener('click', function() {
    const iframe = document.createElement('iframe');
    iframe.setAttribute('width', '560');
    iframe.setAttribute('height', '315');
    iframe.setAttribute('src', 'https://www.youtube.com/embed/IWAVEnXQLCk');
    iframe.setAttribute('frameborder', '0');
    iframe.setAttribute('allowfullscreen', 'allowfullscreen');

    const videoContainer = document.getElementById('video-home-page');
    videoContainer.innerHTML = '';
    videoContainer.appendChild(iframe);
  });
});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
import.meta.webpackHot?.accept(console.error);
